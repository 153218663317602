<template>
  <router-view></router-view>
</template>

<script>
export default {
  data() {
    return {
      parentUrl: '/dossiers-promoteurs',
      links: [
        {
          name: 'Liste des dossiers',
          path: 'list',
          componentName: 'list-dossier-promoteur',
          gate: [
            'consultation,dossier_promoteur',
            'edite,dossier_promoteur',
            'delete,dossier_promoteur',
          ],
        },
        {
          name: 'Synthese du marché',
          path: 'synthese',
          componentName: 'synthese',
          gate: [
            'consultation,dossier_promoteur',
            'edite,dossier_promoteur',
            'delete,dossier_promoteur',
          ],
        },
        {
          name: 'conditions suspensives',
          path: 'conditions-suspensives',
          componentName: 'conditions-suspensives',
          gate: [
            'consultation,dossier_promoteur',
            'edite,dossier_promoteur',
            'delete,dossier_promoteur',
          ],
        },
        {
          name: 'questions matching',
          path: 'questions-matching',
          componentName: 'questions-matching',
          gate: [
            'consultation,dossier_promoteur',
            'edite,dossier_promoteur',
            'delete,dossier_promoteur',
          ],
        },
        {
          name: 'les gammes',
          path: 'les-gammes',
          componentName: 'les-gammes',
          gate: [
            'consultation,dossier_promoteur',
            'edite,dossier_promoteur',
            'delete,dossier_promoteur',
          ],
        },

        {
          name: 'Contexte général',
          path: 'contexte-general',
          componentName: 'contexte-general',
          gate: [
            'consultation,dossier_promoteur',
            'edite,dossier_promoteur',
            'delete,dossier_promoteur',
          ],
        },
        {
          name: 'Timeline',
          path: 'timeline',
          componentName: 'timeline-component',
          gate: [
            'consultation,dossier_promoteur',
            'edite,dossier_promoteur',
            'delete,dossier_promoteur',
          ],
        },
        {
          name: 'Quelques chiffres',
          path: 'quelques-chiffres',
          componentName: 'quelques-chiffres',
        },
        {
          name: 'informations',
          path: 'informations',
          componentName: 'parametrage-informations',
        },
        {
          name: 'Projet By Promy',
          path: 'projet-by-promy',
          componentName: 'projet-by-promy',
        },
      ],
    }
  },
  created() {
    this.$store.commit('subSidebar/LINKS', this.links)
    this.$store.commit('subSidebar/PARENT_URL', this.parentUrl)
  },
  mounted() {
    this.$store.commit('sidebar/RESIZE_WINDOW', true)
  },
  destroyed() {
    this.$store.commit('sidebar/RESIZE_WINDOW', false)
  },
}
</script>

<style></style>
